<template>
  <div>
    <div class='mersive'>
      <el-row class="mersive-row">
        <el-col :xs="{ span: 16, offset: 0 }" :md="{ span: 8, offset: 8 }" :lg="{ span: 8, offset: 8 }" :xl="4">
          <div class="mersive-input">
            <el-input v-model="apiKey" placeholder="请输入令牌" @keyup.enter.native="onSubmit"></el-input>
          </div>
        </el-col>
        <el-col :xs="2" :md="2" >
          <div class="mersive-button">
            <el-button type="primary" @click="onSubmit">查询</el-button>
          </div>
        </el-col>
      </el-row>
    </div>
    <div>
      <el-row>
        <el-col :xs="{ span: 16, offset: 0 }" :md="{ span: 8, offset: 8 }" :lg="{ span: 8, offset: 8 }"  :xl="4">
        <div
        class='mersive-api'
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgb(234 234 234)">
          <el-row>
            <el-col :xs="24" :md="24"  v-if='showState'>
              <div class='mersive-api-item'>
                <el-row>
                  <el-col :span="12">
                    <div>
                      <el-statistic
                        :value="userInfo.amount"
                        title="剩余数量(tokens)"
                      >
                        <template slot="prefix">
                          <i class="el-icon-s-flag" style="color: red"></i>
                        </template>
                      </el-statistic>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </el-col>
            <el-col :xs="24" :md="24" v-else>
              <div class='mersive-api-item'>
                暂无数据
              </div>
            </el-col>
          </el-row>
        </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>

import axios from 'axios';
export default {
  data() {
    return {
      loading: false,
      userInfo: {
        amount: '',
        account: ''
      },
      showState: false,
      apiKey: ''
    }
  },
  methods: {
    onSubmit() {
      const url = '/user'
      this.loading = true
      axios.get(url, {
        headers: {
          'Authorization': `Bearer ${this.apiKey}`,
          'Content-Type': 'application/json'
        }
      }).then(resp => {
        // eslint-disable-next-line no-debugger
        // debugger
        this.loading = false
        this.userInfo = resp.data[0]
        this.showState = true
      }).catch(e => {
        this.showState = false
        this.loading = false
        console.log(e)
      })
    }
  }
}
</script>
<style scoped>
.mersive-row {
  display:flex;
}
.mersive-api {
  margin-top: 50px;
  /* width: 32%; */
  background-color: rgb(234 234 234);
  /* opacity: 0.5; */
  height: 120px;
  border-radius: 7px;
}
.mersive-api-item {
  padding-left: 10px;
  line-height: 60px;
}
.mersive-button {
  padding-left:5px;
}
</style>